import PROMOTION_CONFIG from 'public/src/pages/common/promotion/config.js'
/**
 * 处理促销/obm店铺组头
 * wiki.pageId=998790240
 * @param handleStore 处理店铺数据 出参：店铺组与促销组同级
 * @return [{
 *    group_type:'promotion/store',
 *   id,
 *   list:[{product}]
 * }]
 */
export function handlerCartsPromotionGroup({ goods = [], integrityPromotionInfo = {}, handleStore = false } = {}){
  let promotionGroup = []
  let allPromotionGroup = []
  // 需要分组的促销活动购物车
  const groupedTypeIds = PROMOTION_CONFIG.cartNeedGroupPromotionTypeIds
  let sheinClubOrFullGiftCard = null
  let addBuyAllCard = null
  let othersCard = null
  let group = []
  goods.forEach(item => {
    // 新人专享价商品
    if (item.status == 2 && item.promotion_status == 12) {
      item.isNewcomerItem = true
      item.isDisabled = true
    }

    let isPromotionGroup = false
    item.product.product_promotion_info?.forEach(promotion => {
      const id = promotion.promotion_id
      if(!integrityPromotionInfo[id]) return
      const promotionInfo = integrityPromotionInfo[id] || {}

      // 部分满减 以及分组活动参与分组
      if ((promotion.type_id == 14 && (promotionInfo.goods?.type != 3)) || groupedTypeIds.includes(Number(promotion.type_id))) {
        // 全场满减（特殊的活动，单品分组，全场不分组）
        item.promotionGroupId = id
        // if([2, 28].includes(+promotion.type_id)){ // 部分满赠、买赠有赠品列表或已选赠品时聚合展示
        //   let hasGiftList = promotionInfo.rules?.find(rule => rule.list?.length)
        //   const isPicked = goods.some(item => item.product?.product_promotion_info?.some(p => p.promotion_product_type == '1' && p.promotion_id == id))
        //   if(hasGiftList || isPicked) {
        //     isPromotionGroup = true
        //   }
        // } else {
        //   isPromotionGroup = true
        // }
        isPromotionGroup = true
      }
      if([22, 4].includes(promotion.type_id)){
        if(item.fullPromotionGroup){
          item.fullPromotionGroup[promotion.type_id] = id
        } else {
          item.fullPromotionGroup = {}
          item.fullPromotionGroup[promotion.type_id] = id
        }
      }
      if(promotion.type_id == 4 && !sheinClubOrFullGiftCard){
        sheinClubOrFullGiftCard = JSON.parse(JSON.stringify(integrityPromotionInfo[id]))
        sheinClubOrFullGiftCard.list = []
      }

      if(promotion.type_id == 22 && !addBuyAllCard){
        addBuyAllCard = JSON.parse(JSON.stringify(integrityPromotionInfo[id]))
        addBuyAllCard.list = []
      }

      // 追加特殊商品tag
      if (promotion.type_id == 22) item.isAddBuyAllItem = promotion.promotion_product_type == '2'
      if (promotion.type_id == 13) item.isAddBuyItem = promotion.promotion_product_type == '2'
      if (promotion.type_id == 2) item.isBuyGiftItem = promotion.promotion_product_type == '1'
      if (promotion.type_id == 4) {
        const bool = promotion.promotion_product_type == '1'
        if (promotion.promotion_logo_type === 8) {
          item.isSheinClubGiftItem = bool
        } else {
          item.isFullGiftItem = bool
        }
      }
      if (promotion.type_id == 28) item.isPartFullGiftItem = promotion.promotion_product_type == '1'

      item.isAddItem = Boolean(item.isAddBuyAllItem || item.isAddBuyItem)
      item.isGiftItem = Boolean(item.isBuyGiftItem || item.isSheinClubGiftItem || item.isFullGiftItem || item.isPartFullGiftItem)
      // 特殊商品禁用 checkbox
      item.isDisabled = item.isDisabled || item.isAddItem || item.isGiftItem
    })

    if ((item.isSheinClubGiftItem || item.isFullGiftItem) && sheinClubOrFullGiftCard) { // 全场满赠
      sheinClubOrFullGiftCard.list = []
      if (item.isSheinClubGiftItem) {
        sheinClubOrFullGiftCard.list.unshift(item)
      } else {
        sheinClubOrFullGiftCard.list.push(item)
      }
      if(handleStore){
        handleFullPromotion(sheinClubOrFullGiftCard, group)
      }
      allPromotionGroup.push(sheinClubOrFullGiftCard)
    } else if (item.isAddBuyAllItem && addBuyAllCard) { // 全场加价购
      addBuyAllCard.list = []
      addBuyAllCard.list.push(item)
      if(handleStore ){
        handleFullPromotion(addBuyAllCard, group)
      }
      allPromotionGroup.push(addBuyAllCard)
    } else if (isPromotionGroup ) {
      let item_promotion_info = integrityPromotionInfo[item.promotionGroupId]
      let targetPromotion = promotionGroup.find(cardItem => cardItem.promotion_id == item.promotionGroupId)
      if(targetPromotion) {
        /**
         * 礼品置于卡片顶
         * 2: 买赠;
         * 13: 加价购;
         */
        if (item.isAddBuyItem || item.isBuyGiftItem || item.isPartFullGiftItem) {
          targetPromotion.list.unshift(item)
        } else {
          targetPromotion.list.push(item)
        }
      } else {
        item_promotion_info.list = []
        item_promotion_info.group_type = 'promotion'
        item_promotion_info.list.push(item)
        promotionGroup.push(item_promotion_info)
        group.push(item_promotion_info)
      }
    } else { // 普通商品
      if(handleStore){
        let store_code = item.store_code
        let storeCart =  group.find(groupItem => groupItem.store_code === store_code)
        if(storeCart) {
          storeCart.store_list_origin.push(item)
          storeCart.list.push(item)
          storeCart.preferred_seller_store = item.preferred_seller_store == 1 ? 1 : storeCart.preferred_seller_store
        } else {
          let storeItem = {
            group_type: 'store',
            store_code: item.store_code,
            storeCode: item.store_code,
            store_title: item.store_title,
            store_logo: item.store_logo,
            store_type: item.store_type,
            preferred_seller_store: item.preferred_seller_store,
            list: [],
            store_list_origin: []
          }
          storeItem.store_list_origin.push(item)
          storeItem.list.push(item)
          group.push(storeItem)
        }
      } else {
        if(!othersCard){
          othersCard = { id: 'common', list: [] }
          promotionGroup.push(othersCard) // OBM和Three店铺都关闭情况下, 聚合活动与普通商品组顺序不做特殊处理
        }
        othersCard.list.push(item)
      }
    }
  })
  // if(addBuyAllCard){
  //   promotionGroup.unshift(addBuyAllCard)
  // }
  // if(sheinClubOrFullGiftCard){
  //   promotionGroup.unshift(sheinClubOrFullGiftCard)
  // }
  // othersCard && promotionGroup.push(othersCard)
  return handleStore ? group : allPromotionGroup.concat(promotionGroup)
}

function handleFullPromotion(fullCard, group) {
  fullCard.list.forEach(item => {
    let storeCart =  group.find(groupItem => groupItem.store_code === item.store_code)
    if(storeCart) {
      storeCart.store_list_origin.unshift(item)
      storeCart.list.unshift(item)
      storeCart.preferred_seller_store = item.preferred_seller_store == 1 ? 1 : storeCart.preferred_seller_store
    } else { // 店铺下只有附属品的极端场景
      let storeItem = {
        group_type: 'store',
        store_code: item.store_code,
        storeCode: item.store_code,
        store_title: item.store_title,
        store_logo: item.store_logo,
        store_type: item.store_type,
        preferred_seller_store: item.preferred_seller_store,
        list: [],
        store_list_origin: []
      }
      storeItem.store_list_origin.push(item)
      storeItem.list.push(item)
      group.push(storeItem)
    }
  })
}

export function handleCouponGoodsList(cartsData, goodsList) {
  const newArr = []
  if (!goodsList?.length || !cartsData?.length) return newArr
  cartsData.map(item => {
    if (goodsList.some(val => val?.cart_id == item?.id)) {
      newArr.push(item)
    }
  })
  return newArr
}

// 判断单个商品的聚合类型, 同时向商品中添加特殊标识
/**
 * @description: 获取商品展示时的聚合类型 https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1167730128
 * @param {*} item 商品行item
 * @param {*} integrityPromotionInfo 促销详情
 * @param {*} fullGiftCard 4,全场满赠促销组, 当前阶段默认不传
 * @param {*} addBuyAllCard 22,全场加价购促销组, 当前阶段默认不传
 * @return { 
 *   groupType: { storeLevel: boolean, innerStore: boolean },
 *   specialGroup: { fullGiftCard, addBuyAllCard }
 * } storeLevel: 跨店铺活动聚合, innerStore: 内部内部活动聚合, 其他情况默认在店铺下展示
 *   fullGiftCard: 全场满赠(4), addBuyAllCard: 全场加价购(22)
 */
export function getGroupType(item, integrityPromotionInfo, fullGiftCard = null, addBuyAllCard = null) {
  // 新人专享价商品
  if (item.status == 2 && item.promotion_status == 12) {
    item.isNewcomerItem = true
    item.isDisabled = true
  }

  let groupType = {
    storeLevel: false,
    innerStore: false,
  }
  const groupedTypeIds = PROMOTION_CONFIG.cartNeedGroupPromotionTypeIds
  if(item?.product?.product_promotion_info?.length) {
    item.product.product_promotion_info.forEach(promotion => {
      const id = promotion.promotion_id
      if(!integrityPromotionInfo[id]) return
      const promotionInfo = integrityPromotionInfo[id] || {}

      // 部分满减 以及分组活动参与分组
      if ((promotion.type_id == 14 && (promotionInfo.goods?.type != 3)) || groupedTypeIds.includes(Number(promotion.type_id))) {
        item.promotionGroupId = id
        if(promotionInfo.subject == 1) groupType.storeLevel = true
        else if(promotionInfo.subject == 2) groupType.innerStore = true
      }
      if([22, 4].includes(promotion.type_id)){
        if(item.fullPromotionGroup){
          item.fullPromotionGroup[promotion.type_id] = id
        } else {
          item.fullPromotionGroup = {}
          item.fullPromotionGroup[promotion.type_id] = id
        }
      }
      // 特殊分组
      if(promotion.type_id == 4 && !fullGiftCard){
        fullGiftCard = JSON.parse(JSON.stringify(integrityPromotionInfo[id]))
        fullGiftCard.list = []
      }

      if(promotion.type_id == 22 && !addBuyAllCard){
        addBuyAllCard = JSON.parse(JSON.stringify(integrityPromotionInfo[id]))
        addBuyAllCard.list = []
      }

      // 追加特殊商品tag
      if (promotion.type_id == 22) item.isAddBuyAllItem = promotion.promotion_product_type == '2'
      if (promotion.type_id == 13) item.isAddBuyItem = promotion.promotion_product_type == '2'
      if (promotion.type_id == 2) item.isBuyGiftItem = promotion.promotion_product_type == '1'
      if (promotion.type_id == 4) {
        const bool = promotion.promotion_product_type == '1'
        if (promotion.promotion_logo_type === 8) {
          item.isSheinClubGiftItem = bool
        } else {
          item.isFullGiftItem = bool
        }
      }
      if (promotion.type_id == 28) item.isPartFullGiftItem = promotion.promotion_product_type == '1'

      item.isAddItem = Boolean(item.isAddBuyAllItem || item.isAddBuyItem)
      item.isGiftItem = Boolean(item.isBuyGiftItem || item.isSheinClubGiftItem || item.isFullGiftItem || item.isPartFullGiftItem)
      // 特殊商品禁用 checkbox
      item.isDisabled = item.isDisabled || item.isAddItem || item.isGiftItem
    })
  }
  return {
    groupType,
    specialGroup: {
      fullGiftCard, // 4 全场满赠
      addBuyAllCard // 22 全场加价购
    }
  }
}

export function getStoreItemByStoreCode(mallCart, isSheinStore, cartItem) {
  let store_code = isSheinStore ? 'shein' : cartItem.store_code
  let storeCart = mallCart.mall_list.find(mallItem => mallItem.store_code === store_code)
  if(!storeCart) {
    storeCart = {
      store_code: store_code,
      storeCode: cartItem.store_code,
      store_title: isSheinStore ? 'shein' : cartItem.store_title,
      store_logo: isSheinStore ? '' : cartItem.store_logo,
      store_type: cartItem.store_type,
      preferred_seller_store: cartItem.preferred_seller_store,
      store_list: [],
      store_list_origin: []
    }
    mallCart.mall_list.push(storeCart)
  }
  return storeCart
}

/**
 * 获取de合规价格类型 pageId=1384306511
 * @param {Number} suggestedSalePrice - 30 天最低价
 * @param {Number} retailPrice - 原价
 * @param {Number} unitPriceBeforeCoupon - 促销活动后的价格
 * @returns {Number}
 */
export function getSuggestedPriceType({ suggestedSalePrice, retailPrice, unitPriceBeforeCoupon }) {
  if (suggestedSalePrice > 0) {
    if (suggestedSalePrice < retailPrice) {
      if (retailPrice > unitPriceBeforeCoupon) {
        return 1
      } else if (retailPrice === unitPriceBeforeCoupon) {
        return 2
      }
    } else if (suggestedSalePrice === retailPrice) {
      if (retailPrice > unitPriceBeforeCoupon) {
        return 3
      } else if (retailPrice === unitPriceBeforeCoupon) {
        return 4
      }
    }
    return 5
  } else if (suggestedSalePrice === 0) {
    return 6
  }
  return 0
}

/**
 * 获取商品行的de合规价格类型
 * @param {Object} item 商品行数据
 * @returns 
 */
export function getSuggestedPriceTypeByCartItem(item) {
  return getSuggestedPriceType({
    suggestedSalePrice: +item.product?.suggested_sale_price?.amount,
    retailPrice: +item.product?.retailPrice?.amount,
    unitPriceBeforeCoupon: +item.unit_price_before_coupon?.amount
  })
}

/**
 * 获取分享页/附属品商品行的de合规价格类型
 * @param {Object} item - 商品行数据
 * @returns 
 */
export function getSuggestedPriceTypeByPromoItem(item) {
  return getSuggestedPriceType({
    suggestedSalePrice: +item?.suggested_sale_price?.amount,
    retailPrice: +item?.retail_price?.amount,
    unitPriceBeforeCoupon: +item.sale_price?.amount
  })
}
